import React, { useEffect } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Navigation from "./Navigation";
import About from "./About";
import Resume from "./Resume";
import Portofolio from "./Portofolio";
import { toast } from "react-toastify";

function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        toast.error("The page you requested was not found.");
        navigate(-1);
    }, [navigate]);

    return null;
}

const AnimatedRoutes = () => {
    const location = useLocation();

    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={500}>
                <div className="route-wrapper">
                    <Routes location={location}>
                        <Route path="/" element={<About />} />
                        <Route path="/resume" element={<Resume />} />
                        <Route path="/portofolio" element={<Portofolio />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </CSSTransition>
        </TransitionGroup>
    );
};

const Content = () => {
    return (
        <BrowserRouter>
            <div className="transition-all duration-500 min-w-[270px] max-w-[900px] w-full lg:ml-8 mt-8 lg:mt-0 bg-opacity-40 bg-gray-600 border border-gray-400 rounded-3xl p-7 overflow-hidden lg:relative py-5 text-left text-white">
                <Navigation />
                <AnimatedRoutes />
            </div>
        </BrowserRouter>
    );
};

export default Content;
